(function($){
    'use strict';

    var $linkIconsNav = $('.js-add-link-icons').find('li');
    var iconApplied = false;

    if ($linkIconsNav.length > 0) {

      $linkIconsNav.each(function() {
        var linkText = $(this).text();
        var iconMap = findIconMapping(linkText.toLowerCase());

        if (iconMap !== '') {
          // Icon found, update the markup
          $(this)
            .addClass('has-icon')
            .prepend('<div class="page-links__icon icon-' + iconMap + '"></div>');

          // update the flag
          iconApplied = true;
        }
      });

      if (iconApplied) {
        $('.js-add-link-icons ul').addClass('icon-indent');
      }
    }

    /**
     * Given a string, check for text matches with a list of options.
     * If a match is found, return the corresponding icon name.
     *
     * @param {String} text The plain-text string to check
     *
     * @returns String
     */
    function findIconMapping(text) {
      // The mapping and string test list
      var iconMapping = [
        {
          'testString': 'medical standards',
          'iconName': 'table'
        },
        {
          'testString': 'driving task',
          'iconName': 'car'
        },
        {
          'testString': 'management guidelines',
          'iconName': 'guidelines'
        }
      ];
      var iconMap = '';

      // Iterate over the map
      for (var i = 0, j = iconMapping.length; i < j; ++i) {
        // Check for an partial match in the provided string
        if (text.indexOf(iconMapping[i].testString) !== -1) {
          // Match found, return the icon and stop here
          iconMap = iconMapping[i].iconName;
          break;
        }
      }

      return iconMap;
    }
}(jQuery));