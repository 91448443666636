/* eslint-disable */
(function ($) {
    'use strict';

    var debugFlag = 1;

    $('.form>.form__error.active').each(function() {
        var $error = $(this);

        if($error.text().length) {
            $error.show();
        }
    });

    /* Display genetal form error */
    function displayFormError() {
        if ($('.js-form-loader').length) {
            $('.js-form-loader').fadeOut(300);
        }

        $(".js-submit").prop("disabled", false);

        if ($('.js-form-technical-error').length) {
            $('.js-form-technical-error').show();

            $('html, body').animate({
                scrollTop: 0
            }, 300);
        }
    }

    $(document).ready(function () {
        $.validator.addMethod('titleReq', $.validator.methods.required, 'Please select your title.');
        $.validator.addMethod('firstNameReq', $.validator.methods.required, 'Please enter your first name.');
        $.validator.addMethod('lastNameReq', $.validator.methods.required, 'Please enter your last name.');
        $.validator.addMethod('fullNameReq', $.validator.methods.required, 'Please enter your full name.');
        $.validator.addMethod('emailReq', $.validator.methods.required, 'Please enter your email address.');
        $.validator.addMethod('emailFormat', $.validator.methods.email, 'Please enter a valid email address.');
        $.validator.addMethod('emailRepeatReq', function(value, element) {
            if($(element).closest('form').find('.validation-email').val().length) {
                return value.length;
            } else {
                return true;
            }
        }, 'Please confirm your email address.');
        $.validator.addMethod('emailRepeatMatch', function(value, element) {
            return (value == $(element).closest('form').find('.validation-email').val());
        }, 'Your repeat email address does not match.');
        $.validator.addMethod('passwordReq', $.validator.methods.required, 'Please enter your password.');
        $.validator.addMethod('passwordRepeatReq', function(value, element) {
            if($(element).closest('form').find('.validation-password').val().length) {
                return value.length;
            } else {
                return true;
            }
        }, 'Please confirm your password.');
        $.validator.addMethod('passwordRepeatMatch', function(value, element) {
            return (value == $(element).closest('form').find('.validation-password').val());
        }, 'Your repeat password does not match.');
        $.validator.addMethod('mobileReq', $.validator.methods.required, 'Please enter your mobile number.');
        $.validator.addMethod('addressReq', $.validator.methods.required, 'Please enter your address.');
        $.validator.addMethod('suburbReq', $.validator.methods.required, 'Please enter your suburb/town.');
        $.validator.addMethod('postcodeReq', $.validator.methods.required, 'Please enter your postcode.');
        $.validator.addMethod('stateReq', $.validator.methods.required, 'Please enter your state.');
        $.validator.addMethod('countryReq', $.validator.methods.required, 'Please select your country.');
        $.validator.addMethod('feedbackTypeReq', $.validator.methods.required, 'Please select type of feedback you would like to leave.');
        $.validator.addMethod('feedbackMessageReq', $.validator.methods.required, 'Please enter feedback text.');
        $.validator.addMethod('feedbackResponseReq', $.validator.methods.required, 'Please choose if you would like to get a response.');
        $.validator.addMethod('usernameReq', $.validator.methods.required, 'Please enter your username.');
        $.validator.addMethod('usernameRepeatReq', function(value, element) {
            if($(element).closest('form').find('.validation-username').val().length) {
                return value.length;
            } else {
                return true;
            }
        }, 'Please confirm your username.');
        $.validator.addMethod('usernameRepeatMatch', function(value, element) {
            return (value == $(element).closest('form').find('.validation-username').val());
        }, 'Your repeat username does not match.');

        $.validator.addMethod('requireGroup', function(value, element, options) {
            var validator = this;
            var selector = options[1];
            var validOrNot = $(selector, element.form).filter(function() {
                    return validator.elementValue(this);
                }).length >= options[0];
            return validOrNot;
        }, 'Please select at least one option');

        $.validator.addMethod('shouldBeBlank', function(value) {
            return value === '' || value === undefined || value === null;
        });

        $.validator.addClassRules('purpose', {
         requireGroup: [1, ".purpose"]
        });

        $.validator.addClassRules('validation-title', {
            titleReq: true
        });

        $.validator.addClassRules('validation-first-name', {
            firstNameReq: true
        });

        $.validator.addClassRules('validation-last-name', {
            lastNameReq: true
        });

        $.validator.addClassRules('validation-full-name', {
            fullNameReq: true
        });

        $.validator.addClassRules('validation-email', {
            emailReq: true,
            emailFormat: true
        });

        $.validator.addClassRules('validation-email-repeat', {
            emailRepeatReq: true,
            emailRepeatMatch: true
        });

        $.validator.addClassRules('validation-password', {
            passwordReq: true
        });

        $.validator.addClassRules('validation-password-repeat', {
            passwordRepeatReq: true,
            passwordRepeatMatch: true
        });

        $.validator.addClassRules('validation-username', {
            usernameReq: true
        });

        $.validator.addClassRules('validation-username-repeat', {
            usernameRepeatReq: true,
            usernameRepeatMatch: true
        });

        $.validator.addClassRules('validation-mobile', {
            mobileReq: true
        });

        $.validator.addClassRules('validation-address', {
            addressReq: true
        });

        $.validator.addClassRules('validation-suburb', {
            suburbReq: true
        });

        $.validator.addClassRules('validation-state', {
            stateReq: true
        });

        $.validator.addClassRules('validation-postcode', {
            postcodeReq: true
        });

        $.validator.addClassRules('validation-country', {
            countryReq: true
        });

        $.validator.addClassRules('validation-feedback-type', {
            feedbackTypeReq: true
        });

        $.validator.addClassRules('validation-feedback-message', {
            feedbackMessageReq: true
        });

        $.validator.addClassRules('validation-feedback-response', {
            feedbackResponseReq: true
        });

        $.validator.addClassRules('validation-blank', {
            shouldBeBlank: true
        });

        $.validator.setDefaults({
            ignore:'',
            errorPlacement: function (error, element) {
                var $errorLabel = $(element).closest('.form__field').last().find('.form__error');
                $errorLabel.html(error.text());
            },
            highlight: function (element) {
                $(element).closest('.form__field').addClass('error');
            },
            unhighlight: function (element) {
                $(element).closest('.form__field').removeClass('error');
            }
        });

        if ($('.form-validate').length) {
            $('.form-validate').closest('form').each(function() {
                $(this).validate();
            });
        }

        function concatIds(idsObj) {
            return Object.keys(idsObj).reduce(function(ids, key) {
                return ids.concat(idsObj[key]);
            }, []).join(', ');
        }

        var namesToIds = {
            country: {
                name: '#metadata_field_select_57583',
                state: '#metadata_field_select_57584',
            },
            work: {
                name: '#metadata_field_select_57582',
                austroads: '#metadata_field_select_67579',
                other: '#metadata_field_select_67662',
                otherType: '#metadata_field_text_70354_value'
            },
            student: {
                name: '#metadata_field_select_57588',
                other: '#metadata_field_text_70355_value'
            }
        };
        namesToIds.allWork = concatIds(namesToIds.work);
        namesToIds.allStudent = concatIds(namesToIds.student);
        namesToIds.allWorkAndStudent = namesToIds.allWork + ', ' + namesToIds.allStudent;

        /**
         * Toggle class for show/hide elements
         * @param elements Array of Jquery objects
         */
        function hideElement(elements) {
            elements.forEach(function(element) {
                if (!element.hasClass('hidden')) {
                    element.addClass('hidden');
                }
            });
        }

        /**
         * Remove validation rules from given elements
         * @param elements Array of Jquery objects
         */
        function removeRules(elements) {
            elements.forEach(function(element) {
                if (element.parent().parent().hasClass('hidden')) {
                    element.rules('remove');
                    element.prop("selectedIndex", 0);
                }
            });
        }

        /**
         * Adds Required flag to element
         * @param element
         * @param text
         */
        function makeRequired(element, text) {
            element.rules(
                'add', {
                    required: true,
                    messages: {
                        required: text || 'Please select organisation'
                    }
                }
            );
        }

        /**
         * Change handler for primary reason for using Austroads select
         */
        function primaryReasonChange() {
            switch ($(this).val()) {
                case 'For my work':
                    $('#forWork').removeClass('hidden');
                    $('#forStudies, #otherStudentFreeTxt').addClass('hidden');
                    // hideElement([$('#ANZ'), $('#Austroads'), $('#otherWork'), $('#otherWorkFreeTxt')]);
                    $(namesToIds.work.name).trigger('change');
                    $(namesToIds.work.austroads).trigger('change');
                    $(namesToIds.work.other).trigger('change');
                    removeRules([
                        $(namesToIds.work.austroads),
                        $(namesToIds.work.other),
                        $(namesToIds.work.otherType),
                        $(namesToIds.work.name)
                    ]);
                    $(namesToIds.allStudent).val('');
                    break;
                case 'For my studies':
                    $('#forStudies').removeClass('hidden');
                    $('#forWork, #ANZ, #Austroads, #otherWork, #otherWorkFreeTxt').addClass('hidden');
                    // hideElement([$('#otherStudentFreeTxt')]);
                    $(namesToIds.student.name).trigger('change');
                    removeRules([
                        $(namesToIds.student.other),
                        $(namesToIds.student.name)
                    ]);
                    $(namesToIds.allWork).val('');
                    break;
                default:
                    $(
                        '#forWork, #ANZ, #Austroads, #otherWork, #otherWorkFreeTxt,' +
                        '#forStudies, #otherStudentFreeTxt'
                    ).addClass('hidden');
                    removeRules([
                        $(namesToIds.work.austroads),
                        $(namesToIds.work.other),
                        $(namesToIds.work.otherType),
                        $(namesToIds.work.name),
                        $(namesToIds.student.other),
                        $(namesToIds.student.name)
                    ]);
                    $(namesToIds.allWorkAndStudent).val('');
                    return;
            }
        }

        /**
         * Change handler for work type select
         */
        function workTypeChange() {
            var selectedValue = $(this).val();
            if (selectedValue.match(/^austroads member organisation$/igm) !== null) {
                $('#Austroads').removeClass('hidden');
                makeRequired($(namesToIds.work.austroads));
                hideElement([$('#ANZ'), $('#otherWork')]);
                removeRules([$(namesToIds.work.other), $(namesToIds.work.otherType)]);
            } else if (selectedValue.match(/^anz local or regional council$/igm) !== null) {
                hideElement([$('#Austroads'), $('#otherWork')]);
                removeRules([$(namesToIds.work.austroads), $(namesToIds.work.other), $(namesToIds.work.otherType)]);
            } else if (selectedValue.match(/^other$/igm) !== null) {
                $('#otherWork').removeClass('hidden');
                makeRequired($(namesToIds.work.other));
                hideElement([$('#Austroads'), $('#ANZ')]);
                removeRules([$(namesToIds.work.austroads)]);
            } else {
                hideElement([$('#ANZ'), $('#Austroads'), $('#otherWork')]);
                removeRules([$(namesToIds.work.austroads), $(namesToIds.work.other), $(namesToIds.work.otherType)]);
            }
        }

        /**
         * Change handler for other work type select
         */
        function otherWorkChange() {
            var selectedValue = $(this).val();
            if (selectedValue.match(/^other$/igm) !== null) {
                $('#otherWorkFreeTxt').removeClass('hidden');
                makeRequired($(namesToIds.work.otherType), 'Please tell us who you work for');
            } else {
                hideElement([$('#otherWorkFreeTxt')]);
                removeRules([$(namesToIds.work.otherType)]);
            }
        }
        
        /**
         * Change handler for student type select
         */
        function studentTypeChange() {
            var selectedValue = $(this).val();
            if (selectedValue.match(/^other$/igm) !== null) {
                $('#otherStudentFreeTxt').removeClass('hidden');
                makeRequired($(namesToIds.student.other), 'Please tell us where you study');
            } else {
                hideElement([$('#otherStudentFreeTxt')]);
                removeRules([$(namesToIds.student.other)]);
            }
        }

        /**
         * Change handler for country select
         */
        function countryChange() {
            if ($(this).val().match(/australia|au/igm) !== null) {
                $('#state').removeClass('hidden');
                makeRequired($(namesToIds.country.state), 'Please select your state.');
            } else {
                hideElement([$('#state')]);
                removeRules([$(namesToIds.country.state)]);
            }
        }

        //Registration form custom validation and rules

        if($('#page_account_manager_57527').length) {
            if ($(namesToIds.country.state).val()) {
                $('#state').toggleClass('hidden');
            }
            if ($(namesToIds.work.name).val()) {
                $('#metadata_field_select_57589_For_my_work').prop('checked', true);
                $('#forWork').toggleClass('hidden');
            }
            if ($(namesToIds.work.other).val() || $(namesToIds.work.otherType).val()) {
                $('#otherWork, #otherWorkFreeTxt').toggleClass('hidden');
            }
            if ($(namesToIds.student.name).val()) {
                $('#metadata_field_select_57589_For_my_studies').prop('checked', true);
                $('#forStudies').toggleClass('hidden');
            }            
            if ($(namesToIds.student.other).val()) {
                $('#otherStudentFreeTxt').toggleClass('hidden');
            }
            
            $('[name="metadata_field_select_57589"]').on('change', primaryReasonChange);

            $(namesToIds.work.name).on('change', workTypeChange).trigger('change');

            $(namesToIds.work.other).on('change', otherWorkChange).trigger('change');

            $(namesToIds.student.name).on('change', studentTypeChange).trigger('change');

            $(namesToIds.country.name).on('change', countryChange).trigger('change');

            $(namesToIds.country.name).rules(
                'add', {
                    required: true,
                    messages: {
                        required: 'Please select your country.'
                    }
                }
            );

            $(namesToIds.work.name).rules(
                'add', {
                    required: '#metadata_field_select_57589_For_my_work:checked'
                }
            );

            $(namesToIds.student.name).rules(
                'add', {
                    required: '#metadata_field_select_57589_For_my_studies:checked'
                }
            );

            $('input[id*=metadata_field_select_57589]').rules('add', {
                requireGroup: [1, 'input[id*=metadata_field_select_57589]:checked']
            });
        }
    });

    if ($('.js-form-loader').length) {
        $('.js-form-loader').click(function () {
            return false;
        });
    }

    function handleDuplicates($trigger, $form, $fields) {
        if($trigger.is(':checked')) {
            $fields.each(function() {
                var $field = $(this),
                    fieldName = getPartialClass($field.attr('class'), 'duplicate-parent'),
                    requiredClass = getPartialClass($field.attr('class'), 'validation-'),
                    $fieldChild = $form.find('.duplicate-child-' + fieldName.replace('duplicate-parent-', ''));

                $fieldChild.val($field.val());
            });
        }
    }

    //Field duplication logic
    if ($('.js-duplicate').length) {
        $('.js-duplicate').change(function () {
            var $trigger = $(this),
                $form = $trigger.closest('form'),
                $fields = $form.find('[class*=duplicate-parent]');

            handleDuplicates($trigger, $form, $fields);
        });

        $('.js-duplicate').trigger('change');
    }

    var $subForm = $('#form-subscriptions');

    //Handle profile subscriptions
    if ($subForm.length) {
        var unsubRegex = /unsubscribe-[^=]+=[^=&]+/,
            $insights = $('#subscribe-insights'),
            hasInitialInsights = $insights.closest('fieldset').find('input[type="checkbox"]:checked').length > 0,
            insightsListID = $insights.val(),
            post = []; // Subscriptions array

        function handleSubToggle() {
            // extract element idx
            var idx = this.name.match(/-!?(.*)/i)[1] || null;
            if (idx !== null) {
                var subjectAreaValue = $subForm.find('input[name=subject_area-' + idx + ']').val();
                var subscribe = 'subscribe-' + idx + '=' + this.value;
                var unsubscribe = 'unsubscribe-' + idx + '=' + this.value;

                var sIdx = post.indexOf(subscribe);
                var uIdx = post.indexOf(unsubscribe);

                if (subjectAreaValue !== undefined) {
                    var subjectArea = 'subject_area-' + idx + '=' + subjectAreaValue,
                        subIdx = post.indexOf(subjectArea),
                        insightsIdx = post.indexOf('subscribe-insights=' + insightsListID);

                    if (this.checked && subIdx === -1) {
                        if (insightsIdx === -1) {
                            post.push('subscribe-insights=' + insightsListID);
                        }
                        post.push(subjectArea);
                    } else if (!this.checked && subIdx > -1) {
                        post.splice(subIdx, 1);
                    }
                }
                if (this.checked && sIdx === -1) {
                    post.push(subscribe);
                    if (!subjectAreaValue) {
                        // $(this).addClass('subscribe--unconfirmed');
                    }
                } else if (!this.checked && sIdx > -1) {
                    post.splice(sIdx, 1);
                }
                if (!this.checked && uIdx === -1 && !subjectAreaValue) {
                    post.push(unsubscribe);
                } else if (this.checked && uIdx > -1) {
                    post.splice(uIdx, 1);
                }
            }
        }

        //Remove all hidden unsubscribe fields
        $subForm.find('input').filter(
            function () {
                if (/unsubscribe-.*/igm.test(this.name)) {
                    $(this).remove();
                }
            }
        );

        // Attach event listener to each checkbox
        $subForm.on('change', 'input[type="checkbox"]', handleSubToggle);

        var url = $subForm.data('ajax'),
            method = $subForm.attr('method').toUpperCase();
        
        $subForm.on('submit', function (e) {
            e.preventDefault();
            var $checkedInsights = $insights.closest('fieldset').find('input[type="checkbox"]:checked');
            
            $checkedInsights.each(handleSubToggle);
            
            if (hasInitialInsights && $checkedInsights.length === 0) {
                post.push('unsubscribe-insights=' + insightsListID);
            }
            
            $.ajax({
                type: method,
                url: url,
                data: post.join('&'),
                beforeSend: function() {
                    $('.loader').addClass('active');
                },
                success: function () {
                    // reset array
                    post = [];
                    // $('.subscribe--unconfirmed').prop('disabled', true);
                },
                error: function (e) {
                    console.error(e);
                },
                complete: function () {
                    $('.loader').removeClass('active');
                }
            });
        });
    }

    // reset password
    $('.js-reset-password').on('click', function(e) {
        e.preventDefault();
        var url  = $(this).data('url');
        var form = $(this).closest('form');
        $.ajax({
            url:  url,
            data: form.serialize(),
            type: 'POST',
            beforeSend: function() {
                $('.loader').addClass('active');
            },
            success: function () {
                form.replaceWith('Please check your email for instructions on how to complete the password reset procedure.');
            },
            error: function (e) {
                console.log(e);
            },
            complete: function() {
                $('.loader').removeClass('active');
            }
        });
    });

    var prevEmail = $

    // update personal information
    $('#main_form').submit(function (e) {
        e.preventDefault();
        var $mainForm = $(this);
        var profilePostUrl  = $(this).data('profile_update_url');
        var subscriptionUrl = $(this).data('profile_subscriptions_url');
        var $emailField = $mainForm.find('.profile-email');
        var $currentEmail = $mainForm.find('.profile-current-email');
        $.when(
            $.ajax({
                type: "POST",
                url: profilePostUrl,
                data: $mainForm.serialize(),
                cache: false,
                beforeSend: function() {
                    $('.loader').addClass('active');
                }
            })
        ).then(
            function(resp1) {
               //lets check response for server errors
               var errorsDiv = $(resp1).find('.profile-update-error');
               if (errorsDiv !== undefined && errorsDiv.html() !== '') {
                   $('#sq_backend_sections .profile-update-error').html(errorsDiv.find('li').html());
                   $mainForm.find('.profile-update-error').show();
                   $('.loader').removeClass('active');
               } else {
                   // if all good send data to CM
                    $.ajax({
                        type: "POST",
                        url: subscriptionUrl,
                        data: $mainForm.serialize(),
                        cache: false,
                        error: function (error) {
                            $('#sq_backend_sections .profile-update-error').html(JSON.stringify(error));
                            $mainForm.find('.profile-update-error').show();
                        },
                        complete: function() {
                            $('.loader').removeClass('active');
                        },
                        success: function () {
                            // hide old errors
                            $mainForm.find('.profile-update-error').hide();
                            $currentEmail.val($emailField.val());
                        }
                    })
                }
            },
            function(error) {
                $('#sq_backend_sections .profile-update-error').html(JSON.stringify(error));
                $mainForm.find('.profile-update-error').show();
                $('.loader').removeClass('active');
            }
        );
    });

}(jQuery));
