(function($){
    'use strict';

    $('.js-downloads-text').each(function() {
        var $downloadsText = $(this),
            $downloadsButton = $downloadsText.siblings('button');

        $downloadsButton.click(function() {
            if($downloadsButton.hasClass('active')) {
                $downloadsButton.removeClass('active');
                $downloadsButton.find('span').text('More');
                $downloadsText.slideUp(300, function() {
                    $downloadsText.removeClass('active').removeAttr('style');
                });
            } else {
                $downloadsButton.addClass('active');
                $downloadsButton.find('span').text('Less');
                $downloadsText.slideDown(300, function() {
                    $downloadsText.addClass('active').removeAttr('style');
                });
            }

            return false;
        });
    });

    $('.js-publications-subject').change(function() {
        window.location = '?' + $(this).serialize().replace(/%20/g, '+');
    });
    
}(jQuery));