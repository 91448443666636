/* global debounce */
(function($){
    'use strict';

    function escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    function activateSearch($search) {
        var searchPartialBaseUrl = $search.attr('data-partial-url'),
            searchPartialResults = $search.attr('data-partial-results'),
            collection = $search.attr('data-partial-collection'),
            $searchInput = $search.find('.js-search-input'),
            searchInputName = $searchInput.attr('name'),
            $searchPredictiveList = $search.find('.js-search-list'),
            searchInputValue = '',
            $publicationsSort = $('#publications-sort'),
            searchShowMoreToggle = false;

        if ($search.attr('data-show-more') !== undefined && $search.attr('data-show-more') === 'true') {
            searchShowMoreToggle = true;
        }

        $search.focusout(function(){
            setTimeout(function(){
                var $focused = $search.find(':focus');

                if (!$focused.length) {
                    $searchPredictiveList.fadeOut(300);
                }
            }, 100); //delay to wait until other element recieved focus
        });

        $searchInput.focusin(function(){
            $searchPredictiveList.fadeIn(300);
        }).keydown(function(e){
            if(e.keyCode === 40) {//keydown
                if($searchPredictiveList.find('a').length) {
                    $searchPredictiveList.find('li:first-child a').focus();
                }
            }
        }).keyup(debounce(function () {
            var searchValue = $searchInput.val(),
                searchQuery = searchValue.toLowerCase(),
                searchPartialUrl = searchPartialBaseUrl
                    + '?' + searchInputName + '=' + encodeURIComponent(searchValue)
                    + '&show=' + searchPartialResults + '&collection=' + collection;

            if ($publicationsSort.length) {
                $publicationsSort.val('');
            }

            if(searchInputValue !== searchValue) {
                searchInputValue = $.parseJSON(JSON.stringify(searchValue)); //cloning to break the reference

                if(searchQuery.length) {
                    $.get(searchPartialUrl, function (data) {
                        $searchPredictiveList.empty();
                        var searchQueryRegex = new RegExp('(' + escapeRegExp(searchQuery) + ')', 'gi'),
                            $searchPredictiveListHtml = '<ul>\n';

                        $.each(data, function (key, item) {
                            var searchPredictiveItem = item.disp,
                                searchPredictiveItemHtml = searchPredictiveItem.replace(
                                    searchQueryRegex,
                                    '<span>$1</span>'
                                );
                            $searchPredictiveListHtml += '<li><a href="#">' + searchPredictiveItemHtml + '</a></li>\n';
                        });

                        $searchPredictiveListHtml += '</ul>\n';

                        // Append the show more button, as necessary
                        if (searchShowMoreToggle) {
                            $searchPredictiveListHtml += '<button class="js-search-show-more search__more-results">See more results</button>\n';
                        }

                        $searchPredictiveList.html($searchPredictiveListHtml);

                        $searchPredictiveList.find('a').focusin(function () {
                            $searchInput.val($(this).text());
                        }).keydown(function (e) {
                            if (e.keyCode === 40) { //arrow down
                                var $next = $(this).parent('li').next();

                                if ($next.length) {
                                    $next.find('a').focus();
                                    e.preventDefault();
                                }
                            } else if (e.keyCode === 38) { //arrow up
                                var $prev = $(this).parent('li').prev();

                                if ($prev.length) {
                                    $prev.find('a').focus();
                                    e.preventDefault();
                                }
                            }
                        }).click(function (e) {
                            e.preventDefault();
                            $searchInput.val($(this).text());
                            $searchInput.closest('form').submit();
                            return false;
                        });

                        // Append the show more button event handler, as necessary
                        if (searchShowMoreToggle) {
                            $searchPredictiveList.find('.js-search-show-more').on('click', function(evt) {
                                evt.preventDefault();
                                // Submit the closest search form
                                $(this).closest('form').submit();
                            });
                        }
                    });
                } else {
                    $searchPredictiveList.empty();
                }
            }
        }, 250));
    }

    $('.js-search').each(function () {
        activateSearch($(this));
    });

}(jQuery));
