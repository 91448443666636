(function($){
    'use strict';

    var 
    	$body = $('body'),
    	$headerToggle = $('.js-header-toggle button'),
        $headerTabbingElements = $('.js-header-taborder'),
    	$navigation = $('.js-navigation'),
    	$navigationToggle = $navigation.find('.js-navigation-toggle'),
    	$navigationLink = $navigation.find('.js-navigation-link'),

    	navigationSpaceState = true;

    $headerToggle.click(function() {
    	if($headerToggle.hasClass('active')) {
    		closeNavigation();
    	} else {
    		openNavigation();
    	}

    	return false;
    });

    $navigationToggle.each(function() {
    	var $toggleButton = $(this),
    		$subNavigation = $toggleButton.closest('li').find('.js-navigation-lvl2');

    	$toggleButton.closest('div').addClass('parent');
    	
    	$toggleButton.click(function() {
	        if($toggleButton.hasClass('active')) {
	            $toggleButton.removeClass('active');
	            $subNavigation.slideUp(300, function() {
	                $subNavigation.removeAttr('style').removeClass('active');
	            });
	        } else {
	            $toggleButton.addClass('active');
	            $subNavigation.slideDown(300, function() {
	                $subNavigation.addClass('active').removeAttr('style');
	            });
	        }

            return false;
	    });
    });

    $navigationLink.each(function() {
    	var $link = $(this),
    		$subNavigation = $link.closest('li').find('.js-navigation-lvl2');

    	$link.keyup(function(evt) {// Open/Close level 2 navigation when using keyboard
	        if(!$headerToggle.is(':visible')) {
                if (evt.keyCode == 32 || evt.keyCode == 13) {
                    navigationSpaceState = false;
                }
            }
        }).keydown(function(evt) {
            if(!$headerToggle.is(':visible')) {
                if (evt.keyCode == 32 || evt.keyCode == 13) {
                    navigationSpaceState = true;
                    
                    if($link.hasClass('open')) {
                        $link.removeClass('open');
                        $subNavigation.removeClass('open');
                    } else {
                    	$navigation.find('.open').removeClass('open');
                        $link.addClass('open');
                        $subNavigation.addClass('open');
                    }

                    return false;
                }
            }
        }).on('touchstart', function(){ // Open/Close level 2 navigation on large touch devices
            if(!$headerToggle.is(':visible')) {
                if($link.hasClass('open')) {
                    $link.removeClass('open');
                    $subNavigation.removeClass('open');
                } else {
                    $link.addClass('open');
                    $subNavigation.addClass('open');
                }

                return false;
            }
         });
    });

    function openNavigation() {
		$headerToggle.addClass('active');
		$navigation.addClass('active').on('click', stopNavigation);
		$body.addClass('navigation-open').addClass('navigation-fixed').on('click', closeNavigation); 
        $headerTabbingElements.find('a, button').attr('tabindex', '-1');
    }

    function closeNavigation() {
		$headerToggle.removeClass('active');
		$navigation.removeClass('active').off('click', stopNavigation); 
		$body.removeClass('navigation-open').off('click', closeNavigation);	
        $headerTabbingElements.find('a, button').removeAttr('tabindex');
		setTimeout(function(){
			$body.removeClass('navigation-fixed');
			$navigation.find('.active').removeClass('active'); 
		}, 300);
    }

    function stopNavigation(e) {
    	e.stopPropagation();
    }
    
}(jQuery));