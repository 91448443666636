(function($){
    'use strict';
    //Integration scripts go here

    if($('.js-search-page-filters').length) {
    	setFiltersHeight();

		$(window).resize(function() {
			setFiltersHeight();
		});
    }

    if ($('#publications-sort').length) {
        $('#publications-sort').on('change', function() {
            $(this).closest('form').submit();
        })
    }
}(jQuery));

function setFiltersHeight() {
	var $filters = $('.js-search-page-filters'),
		filtersHeight = Math.ceil($filters.outerHeight()) + 130,
		$container = $filters.closest('.search-page'),
		containerHeight = Math.ceil($container.outerHeight()),
		$filtersButton = $filters.find('.filters__header button'),
		filtersCount = $filters.find('.filters__item').length;

	if(!filtersCount) {
		$filters.hide();
	} else {
		$filters.show();
	}

    if(!$filtersButton.is(':visible')) {
		if(filtersHeight >= containerHeight) {
			$container.css('min-height', filtersHeight + 'px');
		} else {
			$container.css('min-height', '0');
		}
	} else {
		$container.css('min-height', '0');
	}
}
