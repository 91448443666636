(function($){
    'use strict';

    if($('.js-feedback').length){
    	var 
    		$body = $('body'),
    		$feedback = $('.js-feedback'),
    		$feedbackHide = $('.js-feedback-hide'),
    		$feedbackOpen = $('.js-feedback-open'),
    		$feedbackClose = $('.js-feedback-close'),
    		$feedbackWidget = $('.js-feedback-widget'),
			feedbackCookie = getCookie('AustroadsFeedbackWidget');

        if(feedbackCookie == null) { 
			$feedback.fadeIn(300, function() {
	        	$feedback.addClass('active').removeAttr('style');
			});
		}

    	$feedbackHide.click(function(){
			$feedback.fadeOut(300, function() {
	        	$feedback.removeClass('active').removeAttr('style');
			});
        	setCookie('AustroadsFeedbackWidget','closed', 0);

            return false;
    	});

    	$feedbackOpen.click(function(){
    		$feedbackWidget.fadeIn(300);
    		$body.addClass('feedback-open');

            return false;
    	});

    	$feedbackClose.click(function(){
    		$feedbackWidget.fadeOut(300);
    		$body.removeClass('feedback-open');

            return false;
    	});
    }

    function setCookie(name, value, days) {
        var expires = '';

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

}(jQuery));