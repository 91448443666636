(function($){
    'use strict';

    $('.js-tabs').each(function() {
    	var $tabs = $(this),
    		counter = 1;

    	$tabs.prepend('<div class="tabs__controls js-tabs-controls"></div>');
    	var $tabsControls = $tabs.find('.js-tabs-controls');

	    $tabs.find('.js-tabs-title').each(function() {
			var $tabsToggle = $(this),
				$tabsText = $tabsToggle.siblings('.js-tabs-text');

			$tabsToggle.attr('data-tab', counter);
			$tabsText.attr('data-tab', counter);
			$tabsToggle.clone().appendTo($tabsControls);

			var $tabsTab = $tabsControls.find('.js-tabs-title[data-tab="'+counter+'"]');

			$tabsToggle.find('button').click(function() {
				if(!$tabsToggle.hasClass('active')) {

					$tabs.find('.js-tabs-title').removeClass('active');
					$tabs.find('.js-tabs-text').slideUp(300, function() {
						$(this).removeClass('active').removeAttr('style');
					});

					$tabsToggle.addClass('active');
					$tabsTab.addClass('active');
					$tabsText.slideDown(300, function() {
						$tabsText.addClass('active').removeAttr('style');
					});
				}

            	return false;
			});

			$tabsTab.click(function() {
				if(!$tabsToggle.hasClass('active')) {
					$tabs.find('.js-tabs-title, .js-tabs-text').removeClass('active');
					$tabsToggle.addClass('active');
					$tabsTab.addClass('active');
					$tabsText.addClass('active');
				}

            	return false;
			});

			counter++;
		});

    })

}(jQuery));