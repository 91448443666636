(function($){
    'use strict';

    $('.js-toc-custom').each(function () {
        var $toc = $(this),
            $tocMain = $toc.find('.js-toc-main'),
            $ulL3, $ulL2, $ulL1;

        $ulL1 = $tocMain.children('ul').addClass('toc__lvl-1');
        $ulL2 = $ulL1.children('li').children('ul').addClass('toc__lvl-2');
        $ulL3 = $tocMain.find('ul ul ul').addClass('toc__lvl-3');

        function addToggles() {
            $(this).prev('.toc__item').append('<button><span class="sr-only">Open/Close</span></button>');
        }

        $ulL1.find('li').contents().filter(function () {
            return this.nodeType === 3 && this.textContent.trim() !== '';
        }).wrap('<div class="toc__item"><span></span></div>');

        $ulL2.each(addToggles);
        $ulL3.each(addToggles);
        $ulL3.find('span').unwrap('.toc__item');
    });

    $('.js-toc').each(function() {
        var $toc = $(this),
            $tocButton = $toc.find('.js-toc-toggle button'),
            $tocClose = $toc.find('.js-toc-close'),
            $tocMain = $toc.find('.js-toc-main'),
            $tocToggles = $tocMain.find('ul button'),
            $headerToggle = $('.js-header-toggle button'),
            $tocL1Items = $('.toc__lvl-1 > li > .toc__item');

        if(!$toc.hasClass('toc-plain')) {
            $toc.siblings('.content').find('h1, h2, h3').first().addClass('toc__padding');
        }

        function addSectionStyles() {
            var listLabel = $(this).text().trim();

            if (listLabel.indexOf('Part ') === 0) {
                $(this).addClass('section-title');
            }
        }

        $tocL1Items.each(addSectionStyles);

        $tocClose.click(function() {
            $tocButton.removeClass('active');
            if($headerToggle.is(':visible')) {
                $tocMain.slideUp(300, function() {
                    $tocMain.removeClass('active').removeAttr('style');
                });
            } else {
                $tocMain.removeClass('active');
            }

            return false;
        });

        $tocButton.click(function() {
            if($tocButton.hasClass('active')) {
                $tocButton.removeClass('active');
                if($headerToggle.is(':visible') || $toc.hasClass('toc-plain')) {
                    $tocMain.slideUp(300, function() {
                        $tocMain.removeClass('active').removeAttr('style');
                    });
                } else {
                    $tocMain.removeClass('active');
                }
            } else {
                $tocButton.addClass('active');
                if($headerToggle.is(':visible') || $toc.hasClass('toc-plain')) {
                    $tocMain.slideDown(300, function() {
                        $tocMain.addClass('active').removeAttr('style');
                    });
                } else {
                    $tocMain.addClass('active');
                }
            }

            return false;
        });

        $tocToggles.each(function() {
            var $tocToggle = $(this),
                $tocItem = $tocToggle.closest('div'),
                $tocList = $tocItem.siblings('ul');

            $tocItem.addClass('parent');

            $tocToggle.click(function() {
                if($tocToggle.hasClass('active')) {
                    $tocToggle.removeClass('active');
                    $tocList.slideUp(300, function() {
                        $tocList.removeClass('active').removeAttr('style');
                    });
                } else {
                    $tocToggle.addClass('active');
                    $tocList.slideDown(300, function() {
                        $tocList.addClass('active').removeAttr('style');
                    });
                }

                return false;
            });

        });

    });
}(jQuery));