/*global jQuery, Cookies, SITE_URI, LINK_LOOKUP, DETAILS_PAGE, CURRENT_PATH, SUBJECT_AREA_PATH */
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/

var DEBUG = window.location.search.indexOf('DEBUG') !== -1;

//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');

$('.js-logout-link').on('click', function(e) {
    e.preventDefault();
    var href = window.location.href;
    $.get('/?SQ_ASSET_CONTENTS_RAW&SQ_ACTION=logout', function () {
            Cookies.remove('SQ_SYSTEM_SESSION');
            Cookies.set('austroadsCart', {});
        window.location.href = href;
    });
});

$('.content table').each(function() {
    var $table = $(this);
    if(
        !$table.hasClass('dynamic-table')
        && !$table.hasClass('mega-table')
        && !$table.hasClass('custom-table')
        && !$table.hasClass('loader_overlay')
    ) {
        $table.wrap('<div class="table"></div>');
    }
});


//External links
$('.content a[href^="http"]').each(function(i, o) {
    var $anchor = $(o),
        linkHost = $anchor
            .attr('href')
            .replace('http://','')
            .replace('https://','')
            .replace('https://','')
            .split(/[/?#]/)[0];

    if (linkHost !== window.location.hostname) {
        $anchor.attr('target','_blank').addClass('external').append('<span class="sr-only">Opens in new window</span>');
    }
});

//<a href="/publications/agam01-18/structure-of-the-guide#_Ref512960380" class="reflink">Figure 9.1</a>
//<a href="./?a=11488#_Ref492307767" class="reflink">Table 3.1</a>
//{"val":"_Ref512960380","type":"Figure","number":"9.1","desc":"Explanation of dimensions"}

// eslint-disable-next-line no-unused-vars
function getPartialClass(classList, partial) {
    var classes = classList.split(' ');
    
    for(var i = 0, len = classes.length; i < len; i += 1) {
        if(new RegExp(partial).test(classes[i])) {
            return classes[i];
        }
    }
    return false;
}

// eslint-disable-next-line no-unused-vars
function getQueryParams(url) {
    var queryStr = url ? url.split('?')[1] || '' : window.location.search.substring(1),
        pairs = queryStr.split('&');
    if (!pairs.length) { return {}; }
    return pairs.reduce(function (acc, val) {
        var parts = val.split('=').map(decodeURIComponent);
        acc[parts[0]] = parts[1];
        return acc;
    }, {});
}

if (LINK_LOOKUP) {
    $('.reflink').each(function() {
        var $link = $(this),
            key = $link.attr('href').split('#')[1];

        $.ajax(
            LINK_LOOKUP
            + '?details_page=' + DETAILS_PAGE
            + '&key=' + key
            + '&path=' + CURRENT_PATH
            + '&subject_area_path=' + SUBJECT_AREA_PATH
        )
            .done(function(data) {
                if(data.type && data.number) {
                    $link.text(data.type + ' ' + data.number);
                } else {
                    if(DEBUG) {
                        console.log(data);
                    }
                }

            })
            .fail(function(jqXHR, textStatus) {
                if(DEBUG) {
                    console.log(LINK_LOOKUP + '?details_page=' + DETAILS_PAGE + '&key=' + key);
                    console.log('Reflink lookup fail: ' + textStatus);
                }
            });
    });

    $('.seclink').each(function() {
        if (DETAILS_PAGE === '89786') {
            return;
        }
        var $link = $(this),
            key = $link.attr('href').split('#')[1];

        $.ajax(
            LINK_LOOKUP
            + '?details_page=' + DETAILS_PAGE
            + '&key=' + key
            + '&path=' + CURRENT_PATH
            + '&subject_area_path=' + SUBJECT_AREA_PATH
        )
            .done(function(data) {
                if (data.number) {
                    $link.text(data.type === 'Section' ? data.number : data.type + ' ' + data.number);
                } else {
                    if(DEBUG) {
                        console.log(data);
                    }
                }
            })
            .fail(function(jqXHR, textStatus) {
                if(DEBUG) {
                    console.log(LINK_LOOKUP + '?details_page=' + DETAILS_PAGE + '&key=' + key);
                    console.log('Seclink lookup fail: ' + textStatus);
                }
            });
    });
}

// live_view
var internalLink = new RegExp('/' + SITE_URI + '/');
var fixInternalLink = function(href, append) {
    var _href = href.split('?');
    var url = _href.shift();
    var queryString = _href.length ? _href[0].split('&') : [];

    if (append) {
        if (!/SQ_ACTION=view_live/.test(href) && !/SQ_ACTION=logout/.test(href)) {
            queryString.push('SQ_ACTION=view_live');
        }
    } else {
        // remove existing SQ_ACTION and empty vals
        queryString = queryString.reduce(function(accum, curr) {
            if (curr !== 'SQ_ACTION=view_live' && curr !== '') {
                accum.push(curr);
            }
            return accum;
        }, []);
    }

    url += queryString.length ? '?' + queryString.join('&') : '';

    return url;
};
var showHideLiveNotification = function(show) {
    if (show && !(/SQ_ACTION=logout/.test(window.location.href) || /login/.test(window.location.href))) {
        // insert notification header
        $('.page-wrapper').prepend([
            '<div id="live_notification" class="alert">',
            '<div class="alert__wrapper">',
            'You are viewing the live version of this page. ',
            '<a id="remove_live_notification" href="#">Please click here to view the current edits</a>.',
            '</div>',
            '</div>'
        ].join(''));
        $('#remove_live_notification').off().click(function(ev) {
            ev.preventDefault();
            Cookies.remove('view-action', { 'domain': '.' + SITE_URI });
            window.location.href = window.location.href.replace(/[?&]SQ_ACTION=view_live/, '');
        });
    } else {
        $('#live_notification').remove();
    }
};

if (Cookies.get('view-action') === 'view_live') {
    // set radio button if present
    $('input#live-view').prop('checked', true);

    // adjust links as necessary
    $('a').each(function() {
        if (internalLink.test(this.href)) {
            this.href = fixInternalLink(this.href, true);
        }
    });

    showHideLiveNotification(true);
}

$('input[type=radio][name=view-action]').change(function() {
    Cookies.remove('view-action', { 'domain': '.' + SITE_URI });
    if (this.value === 'live_view') {
        Cookies.set('view-action', 'view_live', { 'domain': '.' + SITE_URI });

        // add to links as necessary
        $('a').each(function() {
            if (internalLink.test(this.href)) {
                this.href = fixInternalLink(this.href, true);
            }
        });

        showHideLiveNotification(true);
    } else {
        // remove from links as necessary
        $('a').each(function() {
            if (internalLink.test(this.href)) {
                this.href = fixInternalLink(this.href, false);
            }
        });

        showHideLiveNotification(false);
    }
});

/*
--------------------
Modules
--------------------
*/
