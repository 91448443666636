/* global PROJECT_DETAIL_PAGE */
(function($){
    'use strict';

    $(document).ready( function () {
        $('.dynamic-table').each(function() {
            var $table = $(this),
                // eslint-disable-next-line no-unused-vars
                $tableColumns = $table.find('thead th'),
                // eslint-disable-next-line no-unused-vars
                $tableRows = $table.find('tbody tr');

            //For the future traffic light statuses
            /*if($tableColumns.filter(':contains("Status")').length) {
                var index = $tableColumns.filter(':contains("Status")').index();

                $tableRows.each(function() {
                    var $row = $(this).find('td'),
                        $status = $($row.get(index));

                    switch($status.text()) {
                        case 'On Track':
                            $status.html('<span class="on-track">On Track</span>');
                        break;
                        case 'Late':
                            $status.html('<span class="late">Late</span>');
                        break;
                        case 'Minor Delays':
                            $status.html('<span class="delays">Minor Delays</span>');
                        break;
                    }
                });
            }*/
            // eslint-disable-next-line new-cap
            $table.DataTable({
                responsive:   true,
                stateSave:    false,
                lengthChange: false,
                order:        []
            });
        });

        $('.mega-table').each(function() {
            var $table = $(this);

            if($table.find('thead th[colspan]').length) {
                if($table.find('thead tr').length === 1) {
                    //here
                    var rowHtml = '<tr style="height: 0; border: 0; overflow: hidden;">';
                    $table.find('thead tr').children().each(function(){
                        var $cell = $(this),
                            colspan = $cell.attr('colspan');

                        if(typeof colspan !== typeof undefined && colspan !== false) {
                            var cellsCount = parseInt(colspan, 10);
                            while (cellsCount > 0) {
							    rowHtml += '<th style="padding: 0;"></th>';
							    cellsCount -= 1;
                            }

                        } else {
                            rowHtml += '<th style="padding: 0;"></th>';
                        }

                    });
                    rowHtml += '</tr>';

                    $table.find('thead').append(rowHtml);
                }
            }

            if($table.find('td[colspan]').length) {
                $table.find('td[colspan]').each(function() {
                    var $cell = $(this),
                        cellsCount = parseInt($cell.attr('colspan'), 10) - 1;

                    while (cellsCount > 0) {
                        $cell.after('<td style="display: none;"></td>');
                        cellsCount -= 1;
                    }
                });
            }

            if($table.find('td[rowspan]').length) {
                $table.find('td[rowspan]').each(function() {
                    var $cell = $(this),
                        cellIndex = $cell.parent().children().index($cell),
                        $row = $cell.parent(),
                        cellsCount = parseInt($cell.attr('rowspan'), 10) - 1;

                    while (cellsCount > 0) {
                        $row = $row.next();
                        $row.children().eq(cellIndex).before('<td style="display: none;"></td>');
                        cellsCount -= 1;
                    }
                });
            }
            // eslint-disable-next-line new-cap
            $table.DataTable({
                scrollX:   true,
                ordering:  false,
                scrollY:   500,
                paging:    false,
                searching: false,
                info:      false,
                autoWidth: false
            });
        });

        $('.project-listing-table').each(function() {
            var $table = $(this);

            $table.children('tbody').children('tr').each(function () {
                var row = $(this),
                    idCell = row.children().eq(0),
                    projectID = idCell.text().replace(/\s+/g, ''),
                    nameCell = row.children().eq(1),
                    projectName = nameCell.text(),
                    href = projectID ? PROJECT_DETAIL_PAGE + '?id=' + encodeURIComponent(projectID) : null;

                if (href) {
                    nameCell.html('<a href="' + href + '">' + projectName + '</a>');
                }
            });
            // eslint-disable-next-line new-cap
            $table.DataTable({
                autoWidth:    false,
                responsive:   true,
                stateSave:    false,
                lengthChange: false
            }).columns().header().to$().each(function() {
                this.style.paddingRight = '2.0625rem';
            });
        });
    });
}(jQuery));
