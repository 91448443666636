(function($){
    $('#openTOU').on('click', ()=>{
        $('body').addClass('terms-of-use_open');
        $('.terms-of-use_wrapper').removeClass('hidden');
        $('body').css('position', 'fixed');
        $('body').css('top', `-${window.scrollY}px`);
    })   

    $('#closeTOU').on('click', ()=>{
        const scrollY = document.body.style.top;
        $('body').removeClass('terms-of-use_open');
        $('.terms-of-use_wrapper').addClass('hidden');
        $('body').css('position', '');
        $('body').css('top', '');
        window.scrollTo(0, parseInt(scrollY||'0') * -1);
    })


}(jQuery));