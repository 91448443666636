(function($){
    'use strict';

    $('.js-link-list').each(function() {
    	var $list = $(this),
    		baseUrl = $list.attr('data-url'),
    		$buttons = $list.find('button');

    	$buttons.click(function() {
    		var $button = $(this),
    			parameters = $button.attr('data-parameters');

			$.ajax({
				method: "POST",
				url: baseUrl + parameters,
			}).fail(function(jqXHR, textStatus) {
				console.log('Request failed: ' + textStatus);
			}).done(function(data) {
                var dataSize = JSON.parse(data).length;
				if (dataSize <= 5) {
                    $('.link-list__more').click();
                    $('.link-list__more').remove();
                }
			});

			$button.closest('li').remove();
            return false;
    	});
    });

    if ($('.js-bookmark').length) {
        var url = $('.js-bookmark').data('ajax');
        if (url !== undefined) {
            $('.js-bookmark').click(function (e) {
                e.preventDefault();
                $.ajax({
                    type: "GET",
                    url: url,
                    beforeSend: function () {
                        $('.loader').addClass('active');
                    },
                    success: function () {
                        $('.js-bookmark').replaceWith('<a href="#">Bookmarked</a>');
                    },
                    error: function (e) {
                        console.log(e);
                    },
                    complete: function () {
                        $('.loader').removeClass('active');
                    }
                });
            });
        }
    }

    $('.link-list__more').click(
        function(e) {
            e.preventDefault();
            $('.link-list__more a').toggleClass('expanded');
            var $element = $('.hidden-bookmarks');
            ($element.is(':hidden')) ?
                $element.slideDown('slow', function() {
                    //$('.link-list__more a').toggleClass('expanded');
                }):
                $('.hidden-bookmarks').slideUp('slow', function() {
                    //$('.link-list__more a').toggleClass('expanded');
                });
        }
    );

}(jQuery));
