(function($){
    'use strict';

	var slickConfiguration = {
		standard: {
			autoplay: true,
			autoplaySpeed: 6000,
			arrows: false,
			dots: true,
			infinite: true,
			fade: true,
			mobileFirst: true,
			speed: 600,
		},
		threeUp: {
			autoplay: true,
			autoplaySpeed: 6000,
			arrows: false,
			dots: true,
			infinite: true,
			mobileFirst: true,
			speed: 600,
			centerMode: true,
			centerPadding: '40px',
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 599,
					settings: {
					slidesToShow: 2
				}
				},
				{
					breakpoint: 1103,
					settings: {
						centerMode: false,
						slidesToShow: 3
					}
				}
			]
		}
    };

    $('.js-banner-carousel').each(function() {
    	var $carousel = $(this);
    	var carouselType = 'standard';

    	if ($(this).attr('data-type') !== undefined) {
    		carouselType = $(this).attr('data-type');
    	}

	    $carousel.on('init', function(event, slick){
			if($carousel.hasClass('js-equal-content')) {
				adjustHeight($carousel.find('.js-banner-carousel-content'));

				$( window ).resize(function() {
					adjustHeight($carousel.find('.js-banner-carousel-content'));
				});
			}

			$carousel.find('.slick-dots').wrap('<div class="slick-controls"></div>');
			$carousel.find('.slick-controls').prepend('<div class="slick-play" role="presentation"><button type="button" role="tab" aria-label="Play/Pause">Play/Pause</button></li>');
			$carousel.find('.slick-play button').click(function() {
				playPause($carousel, $(this));
				return false;
			})
		});

	    $carousel.slick( slickConfiguration[carouselType] );
    });

	// On slider breakpoint change
	// Re-add the wrapper classes and calculations
	$('.js-banner-carousel').on('breakpoint', function(event, slick, breakpoint){
		var $carousel = slick.$slider;

		if($carousel.hasClass('js-equal-content')) {
			adjustHeight($carousel.find('.js-banner-carousel-content'));

			$( window ).resize(function() {
				adjustHeight($carousel.find('.js-banner-carousel-content'));
			});
		}

		$carousel.find('.slick-dots').wrap('<div class="slick-controls"></div>');
		$carousel.find('.slick-controls').prepend('<div class="slick-play" role="presentation"><button type="button" role="tab" aria-label="Play/Pause">Play/Pause</button></li>');
		$carousel.find('.slick-play button').click(function() {
			playPause($carousel, $(this));
			return false;
		})
	});

    function adjustHeight($elements) {
    	var maxHeight = 0;

    	$elements.height('auto').each(function() {
    		var $element = $(this);

    		if($element.outerHeight() > maxHeight) {
    			maxHeight = $element.outerHeight();
    		}
    	});

    	$elements.height(maxHeight);
    }

    function playPause($carousel, $toggle) {
	    if ($toggle.hasClass('active') ) {
	        $carousel.slick('slickPlay');
	        $toggle.removeClass('active');
	    } else {
	        $carousel.slick('slickPause');
	        $toggle.addClass('active');
	    }
	}

}(jQuery));