(function($){
    'use strict';

    var $html = $('html'),
        $navigation = $('.js-navigation').find('ul:first'),
        $headerSkip = $('.js-header-skip a'),
        $headerTip = $('.js-header-tip span'),
        $headerSearchToggle = $('.js-header-search-toggle button'),
        $headerSearch = $('.js-header-search');

    $headerSkip.focusin(function(){ //Activating the nav tip when user uses keyboard
            $headerTip.removeClass('active');
        }).focusout(function(){
            $headerTip.addClass('active');
        }).click(function(){
            var $anchorContent = $($(this).attr('href'));

            $('html, body').animate({
                scrollTop: $anchorContent.offset().top
            }, 300, function(){
                $anchorContent.attr('tabindex', -1).on('blur focusout', function () {
                    $(this).removeAttr('tabindex');
                }).focus();
            });

            return false;
    });

    $headerSearchToggle.click(function() {
        if($headerSearchToggle.hasClass('active')) {
            $headerSearchToggle.removeClass('active');
            $navigation.removeClass('search-active');

            if($headerSkip.is(':visible')) {
                $headerSearch.removeClass('active');
            } else {
                $headerSearch.slideUp(300, function() {
                    $headerSearch.removeAttr('style').removeClass('active');
                });                
            }
        } else {
            $headerSearchToggle.addClass('active');
            $navigation.addClass('search-active');

            if($headerSkip.is(':visible')) {
                $headerSearch.addClass('active');
            } else {
                $headerSearch.slideDown(300, function() {
                    $headerSearch.addClass('active').removeAttr('style');
                });               
            }
        }

        return false;
    });
}(jQuery));