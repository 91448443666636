(function($){
    'use strict';

   $('.js-filters button').each(function() {
		var $filterToggle = $(this);

		$filterToggle.click(function() {
			var $filterTitle = $(this).parent(),
				$filterText = $filterTitle.siblings();

			if($filterToggle.hasClass('active')) {
				$filterToggle.removeClass('active');
				$filterText.slideUp(300, function() {
					$filterText.removeClass('active').removeAttr('style');

				    if($('.js-search-page-filters').length) {
				    	setFiltersHeight();
				    }
				});
			} else {
				$filterToggle.addClass('active');
				$filterText.slideDown(300, function() {
					$filterText.addClass('active').removeAttr('style');

				    if($('.js-search-page-filters').length) {
				    	setFiltersHeight();
				    }
				});
			}

			return false;
		});
	});

   $('.js-filters div[data-max-items]').each(function() {
		var $filterBlock = $(this),
			$filterList = $filterBlock.find('ul'),
			filterListCount = $filterBlock.attr('data-max-items');

		if($filterList.children().length > filterListCount) {
			$filterList.children().slice(filterListCount).hide();
			$filterBlock.append('<button class="link">Show all</button>');
			
			$filterBlock.find('button').click(function() {
				$filterList.children().show();
				$(this).remove();

			    if($('.js-search-page-filters').length) {
			    	setFiltersHeight();
			    }

				return false;
			});

		    if($('.js-search-page-filters').length) {
		    	setFiltersHeight();
		    }
		}

	});
}(jQuery));