/* global DEBUG, CART_PAGE, getQueryParams, Cookies */
(function($){
    'use strict';

    function updateCartCookie(cartObj) {
        Cookies.set('austroadsCart', cartObj || {});
    }

    function getCartItemCount() {
        var cartObj = Cookies.getJSON('austroadsCart') || {};
        return Object.keys(cartObj).length;
    }

    function setHeaderCartTicker(url) {
        var $headerCartWrapper = $('.js-header-cart'),
            $headerCartLink = $headerCartWrapper.children('a'),
            $cartReset = $('.js-cart-reset'),
            cartParamRegex = /^(Quantity|buy)\[(\d+)\]/,
            cartObj = Cookies.getJSON('austroadsCart') || {},
            params = getQueryParams(url),
            cartCount;

        // eslint-disable-next-line complexity
        Object.keys(params).forEach(function (key) {
            var productID = key.replace(cartParamRegex, '$2');
            if (cartParamRegex.test(key)) {
                if (key.indexOf('Quantity') === 0 || key.indexOf('buy') === 0 && !cartObj[productID]) {
                    cartObj[productID] = parseInt(params[key], 10) || undefined;
                } else {
                    cartObj[productID] = parseInt(cartObj[productID], 10) + parseInt(params[key], 10);
                }
                updateCartCookie(cartObj);
            }
        });

        if ($cartReset.length) {
            updateCartCookie(null);
        }

        setTimeout(function () {
            cartCount = getCartItemCount();
            if (cartCount) {
                $headerCartLink.append('<span class="count">' + cartCount + '</span>');
            } else if ($headerCartLink.find('.count').length) {
                $headerCartLink.find('.count').remove();
            }
        }, 100);

    }

    $('.js-buy').each(function() {
        var $link = $(this),
            linkUrl = $link.attr('href');

        $link.click(function() {
            $.ajax(linkUrl)
                .done(function(page) {
                    if (DEBUG) {
                        console.log(page);
                    }
                    
                    $link.closest('div')
                        .after([
                            '<div class="publication__note success">',
                            'Product successfully added to ',
                            '<a href="',
                            CART_PAGE,
                            '">cart</a>.</div>'
                        ].join(''));

                    var $note = $link.closest('.publication').find('.publication__note');

                    setHeaderCartTicker(linkUrl);

                    setTimeout(function(){ //Remove note after 5 seconds
                        $note.fadeOut(300, function() {
                            $note.remove();
                        });
                    }, 5000);

                })
                .fail(function(jqXHR, textStatus) {
                    var errorHTML = jqXHR.status === 403
                        ? '<a href="' + linkUrl + '">Please log in to purchase a hardcopy.</a>'
                        : 'Error has occured. Please try again later.';
                    if(DEBUG) {
                        console.log('Purchase fail: ' + textStatus);
                    }


                    $link.closest('div')
                        .after('<div class="publication__note">' + errorHTML + '</div>');
                    var $note = $link.closest('.publication').find('.publication__note');

                    setTimeout(function(){ //Remove note after 5 seconds
                        $note.fadeOut(300, function() {
                            $note.remove();
                        });
                    }, 5000);
                    
                });

            return false;

        });
    });

    $('.js-cart-remove').each(function() {
        var $link = $(this),
            $input = $link.closest('tr').find('input[type="number"]'),
            $submit = $input.siblings('input[type="submit"]');

        $link.click(function() {
            $input.val(0);
            $submit.click();
            return false;
        });
    });

    $('.js-header-cart').each(setHeaderCartTicker);

}(jQuery));
